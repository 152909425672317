import p_1_1 from "./assets/images/1/1.jpg";
import p_1_2 from "./assets/images/1/2.jpg";
import p_1_3 from "./assets/images/1/3.jpg";
import p_1_4 from "./assets/images/1/4.jpg";
import p_1_5 from "./assets/images/1/5.jpg";
import p_1_6 from "./assets/images/1/6.jpg";
import p_1_7 from "./assets/images/1/7.jpg";
import p_1_8 from "./assets/images/1/8.jpg";
import p_1_9 from "./assets/images/1/9.jpg";
import p_1_10 from "./assets/images/1/10.jpg";
import p_1_11 from "./assets/images/1/11.jpg";
import p_1_12 from "./assets/images/1/12.jpg";
import p_1_13 from "./assets/images/1/13.jpg";
import p_1_14 from "./assets/images/1/14.jpg";
import p_1_15 from "./assets/images/1/15.jpg";

import p_2_1 from "./assets/images/2/1.jpg";
import p_2_2 from "./assets/images/2/2.jpg";
import p_2_3 from "./assets/images/2/3.jpg";
import p_2_4 from "./assets/images/2/4.jpg";
import p_2_5 from "./assets/images/2/5.jpg";
import p_2_6 from "./assets/images/2/6.jpg";
import p_2_7 from "./assets/images/2/7.jpg";
import p_2_8 from "./assets/images/2/8.jpg";
import p_2_9 from "./assets/images/2/9.jpg";
import p_2_10 from "./assets/images/2/10.jpg";

export const photos = [
  {
    src: p_1_1,
    width: 4,
    height: 3
  },
  {
    src: p_1_2,
    width: 4,
    height: 3
  },
  {
    src: p_1_3,
    width: 3,
    height: 4
  },
  {
    src: p_1_4,
    width: 4,
    height: 3
  },
  {
    src: p_1_5,
    width: 3,
    height: 4
  },
  {
    src: p_1_6,
    width: 4,
    height: 3
  },
  {
    src: p_1_7,
    width: 4,
    height: 3
  },
  {
    src: p_1_8,
    width: 4,
    height: 3
  },
  {
    src: p_1_9,
    width: 4,
    height: 3
  },
  {
    src: p_1_10,
    width: 4,
    height: 3
  },
  {
    src: p_1_11,
    width: 3,
    height: 4
  },
  {
    src: p_1_12,
    width: 4,
    height: 3
  },
  {
    src: p_1_13,
    width: 4,
    height: 3
  },
  {
    src: p_1_14,
    width: 4,
    height: 3
  },
  {
    src: p_1_15,
    width: 4,
    height: 3
  },


  {
    src: p_2_1,
    width: 4,
    height: 3
  },
  {
    src: p_2_2,
    width: 4,
    height: 3
  },
  {
    src: p_2_3,
    width: 4,
    height: 3
  },
  {
    src: p_2_4,
    width: 4,
    height: 3
  },
  {
    src: p_2_5,
    width: 4,
    height: 3
  },
  {
    src: p_2_6,
    width: 4,
    height: 3
  },
  {
    src: p_2_7,
    width: 4,
    height: 3
  },
  {
    src: p_2_8,
    width: 4,
    height: 3
  },
  {
    src: p_2_9,
    width: 4,
    height: 3
  },
  {
    src: p_2_10,
    width: 4,
    height: 3
  },
];
  