import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Folder';
import NewReleasesIcon from '@material-ui/icons/NewReleasesOutlined';
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    paddingBottom: 50,
  },
  nav: {
    width: '100%',
    position: 'fixed',
    bottom: 0
  }
});

const styles = {
  root: {
    color: "rgba(255, 255, 255, 0.7)"
  },
  selected: {
     color: "rgba(255, 255, 255, 0.7)",
     fontWeight: 'bold'
  }
};


const LabelBottomNavigation = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState('news');
    const actionClasses = props.classes;
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
      if('onHandleChange' in props){
        props.onHandleChange(event, newValue);
      }
    };

    useEffect(() => {
      if('refs' in props){
        props.refs({
          handleChange: handleChange
        })
      }
    });

    return (
        <div className={classes.root}>
            <BottomNavigation value={value} onChange={handleChange} className={classes.nav} style={{backgroundColor: '#333'}}>
                <BottomNavigationAction label="News" value="news" icon={<NewReleasesIcon classes={actionClasses}/>} classes={actionClasses} />
                <BottomNavigationAction label="Recents" value="recents" icon={<RestoreIcon classes={actionClasses}/>} classes={actionClasses} />
                <BottomNavigationAction label="Photos" value="photos" icon={<FavoriteIcon classes={actionClasses}/>} classes={actionClasses} />
            </BottomNavigation>
        </div>
    );
  }

export default withStyles(styles)(LabelBottomNavigation);