import React, { useState } from 'react';
import './App.css';
import PhotoGallery from './components/PhotoGallery'
import RecentGallery from "./components/RecentGallery/RecentGallery";
import NewGallery from "./components/NewGallery/NewGallery";
import { photos } from "./photos";
import { recents } from "./recents";
import DenseAppBar from "./components/DenseAppBar";
import LabelBottomNavigation from './components/LabelBottomNavigation/LabelBottomNavigation'

function App() {
  const [navValue, setNavValue] = useState('news');
  const [labelBottomNavigation, setLabelBottomNavigation] = useState(null);

  const onHandleChange = (event, newValue) => {
    setNavValue(newValue);
  };

  return (
    <div className="App">
      <DenseAppBar />
      <div style={{ paddingBottom: 10 }}>
      {
          navValue === "news" &&
          <NewGallery labelBottomNavigation={labelBottomNavigation}/>
        }
        {
          navValue === "recents" &&
          <RecentGallery data={recents}/>
        }
        {
          navValue === "photos" &&
            <PhotoGallery photos={photos}/>
        }
      </div>
      <LabelBottomNavigation onHandleChange={onHandleChange} refs={(refs) => {
        if(labelBottomNavigation == null){
          setLabelBottomNavigation(refs)
        }
      }}/>
    </div>
  );
}

export default App;
