import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import { makeStyles } from '@material-ui/core/styles';
import Carousel, { Modal, ModalGateway } from "react-images";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/OpenWith';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
 }));

const PhotoListView = props => {
    const { photos, classes } = props;

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
        <GridList className={classes.gridList} cols={2.5}>
        {photos.map((item, index) => (
          <GridListTile key={item.src}>
            <img src={item.src} alt={photos.label} />
            <GridListTileBar
              title={`${item.width}x${item.height}`}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                <IconButton aria-label={`star ${photos.label}`}>
                  <StarBorderIcon className={classes.title} />
                </IconButton>
              }
              onClick={() => openLightbox(index)}
            />
          </GridListTile>
        ))}
      </GridList>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      </div>
    );
}
  

const RecentGallery = props => {
  const { data } = props;

  const classes = useStyles();
  
  return (
    <div>
        {
            data.map((item, index) => {
                return (
                    <div key={index} style={{ textAlign: 'left', color: 'white', paddingTop: 5, paddingBottom: 5 }}>
                        <h1 style={{ padding: 5 }}>{`${item.label} (${item.date})`}</h1>
                        <PhotoListView {...item} classes={classes}/>
                    </div>
                )
            })
        }
    </div>
  );
}

export default RecentGallery;