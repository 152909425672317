import React, { useState } from 'react';
import { Card } from 'react-bootstrap'
import bg from "../../assets/images/bg.jpg";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        color: 'white',
        borderColor: 'white'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const NewGallery = props => {
    console.log("NewGallery: ", props)
    const { labelBottomNavigation } = props;
    var sectionStyle = {
        backgroundImage: `url(${bg})`,
        height: window.innerHeight - 50 - 60,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex'
    }

    const classes = useStyles();

  return (
    <div className="App" style={sectionStyle}>
        <Card style={{backgroundColor: 'transparent', border: 'none'}}>
        <Card.Header style={{backgroundColor: 'transparent', color: 'white',border: 'none'}}>
            <h1>Happy Valentine's Day</h1>
        </Card.Header>
        <Card.Body style={{border: 'none'}}>
            <Card.Title>Tony and Vivian</Card.Title>
            <Card.Text>
            Happy Valentine’s Day to the most special person in my life.
            </Card.Text>
            {/* <Button variant="primary" onClick={() => labelBottomNavigation.handleChange(null, "photos")}>Go somewhere</Button> */}
            <Button variant="outlined" size="large" color="default" className={classes.margin} onClick={() => labelBottomNavigation.handleChange(null, "photos")}>
            Go to Photos
            </Button>
        </Card.Body>
        </Card>
    </div>
  );
}

export default NewGallery;